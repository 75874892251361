:root {
  /* Button Colours */
  --button-border-hover-colour: #036cd4;
  --button-text-dark-colour: #000000c6;
  --button-text-light-colour: #ffffff;
  --button-text-hover-colour: #036cd4;

  /* Operand Button Colours */
  --operand-button-colour: #a0a0a0;
  --operand-button-hover-colour: #c5c5c5;
  --operand-button-active-colour: #dcdada;

  /* Operator Button Colours */
  --operator-button-colour: #fab70f;
  --operator-button-hover-colour: #fa9c0f;
  --operator-button-active-colour: #ffbe5e;

  /* Function Button Colours */
  --function-button-colour: #e8e8e8;
  --function-button-hover-colour: #e0e0e0;
  --function-button-active-colour: #ffffff;

  /* Solve Button Colours */
  --solve-button-colour: #fab70f;
  --solve-button-hover-colour: #fa9c0f;
  --solve-button-active-colour: #ffbe5e;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
  margin-right: 12.5%;
  font-size: clamp(0.5rem, 1.5vw, 1rem);
}

.expression {
  color: #808080;
  min-height: 20px;
  min-width: 100px;
}

/* Block Button */
.block-button {
  width: 100%;
  height: 100%;
  font-size: 1.5em;
  font-weight: 1000;
  border-radius: 20px;
  border: 1px solid var(--button-border-colour);
  transition:
    background-color 0.8s,
    border-color 0.8s,
    color 0.8s,
    box-shadow 0.8s;
  box-shadow: -2px 4px 4px #0000006b;
}
.block-button:hover {
  transition:
    background-color 0.1s,
    border-color 0.1s,
    color 0.1s;
  border-color: var(--button-border-hover-colour) !important;
  color: var(--button-text-hover-colour) !important;
}
.block-button::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  transition: all 0.3s;
  border-radius: inherit;
}
.block-button:hover::before {
  opacity: 0;
}
.block-button:active {
  transition:
    background-color 0.1s,
    border-color 0.1s,
    color 0.1s,
    box-shadow 0.1s;
    box-shadow: 0px 0px 0px #00000000;
}

/* Operand Button */
.operand-button.block-button {
  color: var(--button-text-light-colour);
  border: 1px solid var(--operand-button-colour);
  background-color: var(--operand-button-colour);
}
.operand-button.block-button:hover {
  background-color: var(--operand-button-hover-colour) !important;
}
.operand-button.block-button:active {
  background-color: var(--operand-button-active-colour) !important;
}

/* Operator Button */
.operator-button.block-button {
  color: var(--button-text-light-colour);
  border: 1px solid var(--operator-button-colour);
  background-color: var(--operator-button-colour);
}
.operator-button.block-button:hover {
  background-color: var(--operator-button-hover-colour) !important;
}
.operator-button.block-button:active {
  background-color: var(--operator-button-active-colour) !important;
}

/* Function Button */
.function-button.block-button {
  color: var(--button-text-dark-colour);
  border: 1px solid var(--function-button-colour);
  background-color: var(--function-button-colour);
}
.function-button.block-button:hover {
  background-color: var(--function-button-hover-colour) !important;
}
.function-button.block-button:active {
  background-color: var(--function-button-active-colour) !important;
}

/* Solve Button */
.solve-button.block-button {
  color: var(--button-text-light-colour);
  border: 1px solid var(--solve-button-colour);
  background-color: var(--solve-button-colour);
}
.solve-button.block-button:hover {
  background-color: var(--solve-button-hover-colour) !important;
}
.solve-button.block-button:active {
  background-color: var(--solve-button-active-colour) !important;
}

/* Display Screen */
.display-screen {
  background-color: #caffce;
  color: black;
  border-radius: 20px;
  padding: 10px;
  height: 100px;
  width: 75%;
  margin: 2% auto 0 auto;
  display: flex;
  justify-content: flex-end;
  box-shadow: inset -1px 2px 2px #0000006b;
  font-size: 2em;
  font-weight: 600;
  text-align: left;
}

.display-screen:hover {
  transform: scale(1.05);
}

/* Calculator */
.calculator {
  background-color: #3c3c3c;
  padding: 15px;
  border-radius: 20px;
  height: 500px;
  width: 75%;
  margin: 2% auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: -2px 4px 4px #0000006b;
}

.calculator .ant-row {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.calculator .ant-col {
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 25%;
}

.calculator .wide-block-button {
  width: 50%;
}
